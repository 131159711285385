import React from 'react';
import './Result.css'; // Import CSS for styling

const Result = () => {
 
    return (
        <>
        <div className='section-background-color' id='result'>
            <div className='container'>
                <h1 className="heading-gradient">Results</h1>

                <div class="mainwe1">

                    <div class="firstwe1">
                    <div className="embed-responsive embed-responsive-16by9">
                            <iframe className="embed-responsive-item vdo" src="https://www.youtube.com/embed/w5UrGT2z0RU?rel=0" allowFullScreen title="YouTube Video"></iframe>
                        </div>
                        <h3>Strong years of experience</h3>
                        <p>We have strong experience in generating quality leads or growing & scaling your business. You can just relax and let our team take things forward.</p>

                        <img class="imgsize" src="https://blueslag.com/new/wp-content/uploads/2024/06/shopify.svg" alt="" />
                    <img class="imgsize" src="https://blueslag.com/new/wp-content/uploads/2024/06/clutch.svg" alt="" />
                    <img class="imgsize" src="https://blueslag.com/new/wp-content/uploads/2024/06/trustpilot.svg" alt="" />
                    <img class="imgsize" src="https://blueslag.com/new/wp-content/uploads/2024/06/google.svg" alt="" />
                    <img class="imgsize" src="https://blueslag.com/new/wp-content/uploads/2022/08/review.svg" alt="" /> 
                    <img class="imgsize" src="https://blueslag.com/new/wp-content/uploads/2024/06/trustpilot.svg" alt="" />
                    </div>  

                    <div class="secwe1">
                    <div className="embed-responsive embed-responsive-16by9">
                            <iframe className="embed-responsive-item vdo" src="https://www.youtube.com/embed/w5UrGT2z0RU?rel=0" allowFullScreen title="YouTube Video"></iframe>
                        </div>
                        <h3>Result-driven approach</h3>
                        <p>In a crowded market, our creative team knows what it takes to get customers' attention. Hence, we create highly optimized visuals & drives action.</p>
                        <img class="imgsize" src="https://blueslag.com/new/wp-content/uploads/2024/06/shopify.svg" alt="" />
                    <img class="imgsize" src="https://blueslag.com/new/wp-content/uploads/2024/06/clutch.svg" alt="" />
                    <img class="imgsize" src="https://blueslag.com/new/wp-content/uploads/2024/06/trustpilot.svg" alt="" />
                    <img class="imgsize" src="https://blueslag.com/new/wp-content/uploads/2024/06/google.svg" alt="" />
                    <img class="imgsize" src="https://blueslag.com/new/wp-content/uploads/2022/08/review.svg" alt="" /> 
                    <img class="imgsize" src="https://blueslag.com/new/wp-content/uploads/2024/06/trustpilot.svg" alt="" />
                    </div>
              
                </div>

            </div>
        </div>
        </>
    );
};
        
export default Result;