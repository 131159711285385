import React from 'react';
import './Copyright.css'; // Import CSS for styling

const Copyright = () => {
 
    return (
        <>
       <div className="copyright-background-color">
            <p>Copyright ©2019-2024 Blueslag Technolgies. All Rights Reserved</p>
       </div>
       </>
    );
};
        
export default Copyright;