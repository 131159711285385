import React from 'react';
import './Circle.css';

const Circle = () => {
    return (
        <>
        <div className="section-background-color" >
        <div className="container">
            <div className="mainach">

<div className="secondach">
    <h3>5Cr+</h3>
    <p>ads spend</p>
</div>

<div className="secondach">
    <h3>25Cr+</h3>
    <p>revenue</p>
</div>

<div className="secondach">
    <h3>5Yr+</h3>
    <p>experience</p>
</div>

<div className="secondach">
    <h3>50+</h3>
    <p>clients</p>
</div>

</div> </div></div>
        </>
    );
};

export default Circle;
