import React from 'react';
import './LogoSection.css';
const LogoSection = () => {
    return (
        <>
        <div className="section-background-color-dark" >
        <div className="container">
            <div className="bordertop">
                    <h2>People we've trained work at</h2>
                        <div>
                        <img className="imgsize1" src="https://blueslag.com/wp-content/uploads/2024/07/black-e1720182412142.png" alt="" />
                        <img className="imgsize7" src="https://blueslag.com/wp-content/uploads/2024/07/blbg-1-e1720182480205.png" alt="" />
                        <img className="imgsize2" src="https://blueslag.com/wp-content/uploads/2024/07/blbg-11.png" alt="" />                        
                        <img className="imgsize4" src="https://blueslag.com/wp-content/uploads/2024/07/blbg-4.png" alt="" />
                        <img className="imgsize5" src="https://blueslag.com/wp-content/uploads/2024/07/blbg-8.png" alt="" />
                        <img className="imgsize11" src="https://blueslag.com/wp-content/uploads/2024/07/blbg-6-e1720181471595.png" alt="" />                       
                        <br />
                        <img className="imgsize8" src="https://blueslag.com/wp-content/uploads/2024/07/blbg-9-e1720181414994.png" alt="" />  
                        <img className="imgsize22" src="https://blueslag.com/wp-content/uploads/2024/07/blbg-3.png" alt="" />        
                        <img className="imgsize6" src="https://blueslag.com/wp-content/uploads/2024/07/blbg-2.png" alt="" />
                        <img className="imgsize3" src="https://blueslag.com/wp-content/uploads/2024/07/blbg-12-e1720181348687.png" alt="" />
                        <img className="imgsize9" src="https://blueslag.com/wp-content/uploads/2024/07/blbg-7.png" alt="" />                       
                        <img className="imgsize33" src="https://blueslag.com/wp-content/uploads/2024/07/blbg-5.png" alt="" />
                        </div>
                    </div>
                    </div></div>
        </>
    );
};

export default LogoSection;
